import { useMemo, useState } from "react";
import { Checkbox } from "components/atoms/checkbox";
import { CircleX, Plus, ScanText } from "lucide-react";
import { PopoverClose } from "@radix-ui/react-popover";
import { CORE_CRITERIA, SENSITIVITY_OPTIONS } from "./constants";
import { ReactComponent as ScanPlus } from "../../../Assets/scan-plus.svg";
import useProposalReviewEngine, { CriteriaParams } from "./hooks";
import Slider from "components/molecules/slider";

const ReviewEnginePopover = ({ proposalDocId }: { proposalDocId: string }) => {
  const [coreCriteria, setCoreCriteria] = useState<string[]>([]);
  const [strictness, setSensitivity] = useState(1);
  const [userDefinedCriteria, setUserDefinedCriteria] = useState<string[]>([""]);
  const fetchProposalReviewEngine = useProposalReviewEngine();

  const handleInputChange = (index: number, newValue: string) => {
    setUserDefinedCriteria((prev) => {
      const newCriteria = [...prev];
      newCriteria[index] = newValue;
      return newCriteria;
    });
  };

  const handleRemoveInput = (index: number) => {
    setUserDefinedCriteria((prev) => prev.filter((_, i) => i !== index));
  };

  const criteriaEntries = useMemo(() => Object.entries(CORE_CRITERIA), []);

  const hasAddedCriteria = useMemo(() => {
    const hasNonEmptyCoreCriteria = coreCriteria.length > 0;
    const hasNonEmptyUserDefinedCriteria = userDefinedCriteria.some((criteria) => criteria.trim() !== "");

    return hasNonEmptyCoreCriteria || hasNonEmptyUserDefinedCriteria;
  }, [coreCriteria, userDefinedCriteria]);

  const handleStartReview = () => {
    const params: CriteriaParams = {};

    const filteredCustomCriteria = userDefinedCriteria.filter((value) => value.trim() !== "");
    if (coreCriteria.length > 0) {
      params.criteria = coreCriteria;
    }
    if (filteredCustomCriteria.length > 0) {
      params.custom_criteria = filteredCustomCriteria;
    }

    params.strictness = SENSITIVITY_OPTIONS[strictness].value;

    fetchProposalReviewEngine(proposalDocId, params);
  };

  return (
    <div className="flex flex-col gap-1 min-w-[415px] max-w-[415px] overflow-scroll max-h-[80vh]">
      <div className="font-medium text-lg px-4 pb-2 pt-3">Review</div>
      <div className="flex flex-col gap-0.5 px-4 pb-3">
        <div className="font-medium text-base flex items-center justify-start gap-1 mb-1">
          <ScanText size={16} /> Core Criteria
        </div>
        <div className="text-[#7C8186] text-sm mb-3.5">Review against a set of pre-defined criteria.</div>
        {criteriaEntries.map(([key, value]) => (
          <span className="text-sm flex items-center gap-1.5 mb-1" key={key}>
            <Checkbox
              className="my-0.5 ml-0.5 mr-1 h-[10px] w-[10px]"
              variant="primaryBlack"
              label={value}
              checked={coreCriteria.includes(key)}
              onCheck={(checked) => {
                if (checked) setCoreCriteria((prev) => [...prev, key]);
                else setCoreCriteria((prev) => prev.filter((c) => c !== key));
              }}
              size="sm"
              id={key}
            />
            <label htmlFor={key} className="cursor-pointer">
              {value}
            </label>
          </span>
        ))}
        <hr className="border-[#E5E5E5] my-4" />
        <div className="font-medium text-base flex items-center justify-start gap-1 mb-1">
          <ScanPlus size={16} /> User-Defined Criteria
        </div>
        <div className="flex justify-between items-center mb-2">
          <div className="text-[#7C8186] text-sm">Review against a set of user-defined criteria.</div>
          <button
            className="flex items-center justify-center text-xs text-gray-500 underline focus:outline-none h-5 w-5 bg-gray-lightest border rounded-sm"
            onClick={() => setUserDefinedCriteria([...userDefinedCriteria, ""])}
          >
            <Plus size={14} />
          </button>
        </div>
        {userDefinedCriteria.map((criteria, i) => (
          <div className="relative flex items-center mt-1" key={i}>
            <input
              type="text"
              className="mb-1 h-8 w-full px-2 border bg-gray-lightest rounded text-xs focus:border-gray-900 focus:outline-none"
              placeholder="+ Insert criteria"
              value={criteria}
              onChange={(e) => handleInputChange(i, e.target.value)}
            />
            {userDefinedCriteria.length > 1 && (
              <button
                className="absolute right-0 top-0 h-8 px-2 text-gray-500 bg-transparent border-none cursor-pointer focus:outline-none"
                onClick={() => handleRemoveInput(i)}
              >
                <CircleX size={12} />
              </button>
            )}
          </div>
        ))}

        <hr className="border-[#E5E5E5] my-4" />
        <div className="font-medium text-base flex items-center justify-start gap-1 mb-1">
          <ScanPlus size={16} /> Review Level
        </div>
        <div className="text-sm">{SENSITIVITY_OPTIONS[strictness].label}</div>
        <div className="text-[#7C8186] text-sm mb-2">{SENSITIVITY_OPTIONS[strictness].description}</div>
        <Slider
          min={0}
          max={2}
          step={1}
          defaultValue={[strictness]}
          onValueChange={(value) => setSensitivity(value[0])}
        />
      </div>
      <PopoverClose
        className="min-h-8 mx-3 mb-3 flex items-center justify-center gap-1.5 rounded border text-white text-xs bg-gray-darkest disabled:bg-gray-200"
        disabled={!hasAddedCriteria}
        onClick={() => {
          handleStartReview();
        }}
      >
        Start Review
      </PopoverClose>
    </div>
  );
};

export default ReviewEnginePopover;
