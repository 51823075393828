/** @jsxImportSource @emotion/react */

import * as Accordion from "@radix-ui/react-accordion";
import { Extraction, ExtractionStatus, Volume } from "components/copilot/CopilotSchemaTypes";
import { ChevronRight, CirclePlus, EllipsisVertical, Plus } from "lucide-react";
import Icon from "components/atoms/icons/Icon";
import SectionRow from "../section-row";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { HTMLAttributes, forwardRef, memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  setActiveSection,
  setEditableTemplateRowState,
  setTemplateOpenState,
} from "store/reducers/extract/CurrentExtractionReducer";
import { StepValue } from "../../../types";
import tw, { theme } from "twin.macro";
import useExtractionOperations from "hook/useExtractionOperations";
import { useDraftDropdownItems } from "../hooks";
import { useDrag } from "./hooks";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { DndContext, DragOverlay, DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import SortableItem from "../section-row/SortableItem";
import { verticalSortableListCollisionDetection } from "pages/draft-volume/draft-volume-sidebar/utils";
import { ToImmutable } from "YJSProvider/LiveObjects";
import Tooltip from "components/atoms/tooltip";
import { isInstantDraftStarted } from "../../../utils";

interface Props extends HTMLAttributes<HTMLDivElement> {
  draft: ToImmutable<Omit<Volume, "sections">> & { sections: FormattedSection[] };
  groupedComplianceMatrix: Record<string, ToImmutable<Extraction["compliance_matrix"]>>;
  withOpacity?: boolean;
  isDragging?: boolean;
  dragProps?: { attributes?: DraggableAttributes; listeners?: SyntheticListenerMap };
}

const DraftRow = forwardRef<HTMLDivElement, Props>(
  ({ dragProps, isDragging, withOpacity, draft, style, groupedComplianceMatrix }, ref) => {
    const [portalRef, setPortalRef] = useState<HTMLDivElement | null>(null);
    const openState = useAppSelector((store) => store.currentExtractionState.templateOpenState);
    const editableTemplateRowState = useAppSelector((store) => store.currentExtractionState.editableTemplateRowState);

    const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
    const { sensors, dragSections, handleDragEnd, handleDragStart, handleDragCancel, activeDragId } = useDrag(
      draft.sections,
      draft.id,
      extraction?.id
    );
    const activeSection = useAppSelector((store) => store.currentExtractionState.activeSection);
    const dispatch = useAppDispatch();
    const isReadOnly =
      extraction?.status === ExtractionStatus.Completed ||
      isInstantDraftStarted(extraction?.instantDraftConfig?.status);
    const { setDraftName, addNewSection } = useExtractionOperations();
    const { items } = useDraftDropdownItems(draft, extraction?.id);
    const isImportStep = extraction?.step === StepValue.Review;
    const isEditing = editableTemplateRowState.id === draft.id;

    useEffect(() => {
      if (StepValue.Assign !== extraction?.step) return;

      const isDraftClosed = !openState.includes(draft.id);

      if (isDraftClosed) {
        const activeSectionExistsWithinDraft = draft.sections.some(
          (section) =>
            section.id === activeSection?.id ||
            section.subsections?.some((subsection) => subsection.id === activeSection?.id)
        );

        if (activeSectionExistsWithinDraft) setTimeout(() => dispatch(setActiveSection(undefined)), 200);
        return;
      }

      const activeSectionExistsWithinSection = draft.sections.some((section) => {
        const isSectionClosed = !openState.includes(section.id);
        return isSectionClosed && section.subsections?.some((subsection) => subsection.id === activeSection?.id);
      });

      if (activeSectionExistsWithinSection) setTimeout(() => dispatch(setActiveSection(undefined)), 200);
    }, [activeSection?.id, dispatch, draft.id, draft.sections, extraction?.step, openState]);

    const activeDragSection = useMemo(
      () => draft.sections.find((draft) => draft.id === activeDragId),
      [activeDragId, draft.sections]
    );

    const handleAddSection = useCallback(() => {
      if (extraction?.id) {
        const createdSection = addNewSection(extraction.id, draft.id);
        if (!openState.includes(draft.id)) {
          dispatch(setTemplateOpenState([...openState, draft.id]));
        }
        setTimeout(
          () =>
            dispatch(
              setEditableTemplateRowState({
                localValue: createdSection.title,
                id: createdSection.id,
              })
            ),
          80
        );
      }
    }, [addNewSection, dispatch, draft.id, extraction?.id, openState]);

    return (
      <div
        className="rounded-md px-1"
        ref={ref}
        css={[
          isDragging && tw`bg-white`,
          isDragging && isImportStep && tw`bg-layout-gray-light`,
          {
            boxShadow: isDragging ? theme`boxShadow.expanded` : "none",
            zIndex: isDragging ? "4" : "auto",
            opacity: withOpacity ? "0.3" : "1",
            pointerEvents: isEditing ? "none" : "auto",
            ...style,
          },
        ]}
        {...dragProps?.attributes}
      >
        <Accordion.Item ref={setPortalRef} key={draft.id} value={draft.id}>
          <div className="group gap-0.5 flex flex-row items-center">
            {!isReadOnly && (
              <div
                className="z-[1] bg-transparent rounded p-1 text-slate-500 hover:text-slate-900 hover:bg-slate-200"
                css={[{ cursor: isDragging ? "grabbing" : "grab" }]}
                {...dragProps?.listeners}
              >
                <Icon name="Draggable" className="w-3 h-3" />
              </div>
            )}
            <Accordion.Header className="relative group flex-1 w-[calc(100%-22px)]" id={draft.id}>
              <Accordion.Trigger
                disabled={isEditing}
                title={draft.title}
                className="overflow-hidden text-gray-darkest flex items-center gap-2 w-full text-left py-1 pr-4"
              >
                <ChevronRight
                  size={14}
                  className="flex-shrink-0 transition-transform duration-200 group-data-[state=open]:rotate-90"
                  css={[!draft.sections.length && tw`text-gray-400`]}
                />
                <Icon name="Draft" className="min-w-3" />
                {editableTemplateRowState.id === draft.id ? (
                  <input
                    autoFocus
                    onChange={(e) => {
                      dispatch(setEditableTemplateRowState({ localValue: e.target.value }));
                      e.stopPropagation();
                    }}
                    placeholder="Draft title..."
                    onKeyDown={(e) => {
                      if (e.code === "Enter") {
                        if (extraction?.id) {
                          setDraftName(extraction.id, draft.id, editableTemplateRowState.localValue);
                        }
                        setTimeout(() => dispatch(setEditableTemplateRowState({ id: "", localValue: "" })), 100);
                      }
                    }}
                    onBlur={() => {
                      if (extraction?.id) {
                        setDraftName(extraction.id, draft.id, editableTemplateRowState.localValue);
                      }
                      setTimeout(() => dispatch(setEditableTemplateRowState({ id: "", localValue: "" })), 100);
                    }}
                    value={editableTemplateRowState.localValue}
                    className="text-sm py-2 font-semibold outline-none text-gray-darkest w-full bg-transparent"
                  />
                ) : (
                  <div className="truncate text-sm font-semibold py-2" css={[!draft.title.trim() && tw`text-gray-400`]}>
                    {draft.title || "Draft title..."}

                    {!isEditing && !isReadOnly && (
                      <div
                        className="absolute bottom-0 right-0 top-0 to-transparent bg-gradient-to-l w-14 group-hover:w-24 group-hover:from-50% group-hover:from-white"
                        css={[isImportStep && tw`group-hover:from-layout-gray-light group-hover:w-16`]}
                      />
                    )}
                  </div>
                )}
              </Accordion.Trigger>
              {!isReadOnly && (
                <div
                  className="opacity-0 text-slate-700 flex items-center gap-1.5 text-sm absolute bottom-0 right-0 top-0 pl-2 pr-2 bg-white group-hover:opacity-100"
                  css={[isEditing && tw`hidden`, isImportStep && tw`bg-layout-gray-light`]}
                >
                  {!isImportStep && (
                    <Tooltip content="Add section">
                      <button onClick={handleAddSection}>
                        <CirclePlus size={16} />
                      </button>
                    </Tooltip>
                  )}
                  <DropdownMenu
                    portalProps={{ container: portalRef }}
                    contentProps={{ align: "end", css: tw`min-w-[120px]` }}
                    items={items}
                  >
                    <div className="">
                      <EllipsisVertical size={14} />
                    </div>
                  </DropdownMenu>
                </div>
              )}
            </Accordion.Header>
          </div>
          <Accordion.Content className="collapsibleContent cursor-default">
            <DndContext
              sensors={sensors}
              collisionDetection={verticalSortableListCollisionDetection}
              onDragEnd={(event) => handleDragEnd(event)}
              onDragStart={handleDragStart}
              onDragCancel={handleDragCancel}
            >
              <SortableContext
                id="EXTRACTION_TEMPLATE_SECTIONS"
                items={dragSections || []}
                strategy={verticalListSortingStrategy}
              >
                {dragSections?.map((section) => {
                  return (
                    <SortableItem
                      key={section.id}
                      volumeId={draft.id}
                      section={section}
                      groupedComplianceMatrix={groupedComplianceMatrix}
                    />
                  );
                })}
                <DragOverlay style={{ transformOrigin: "0 0 " }}>
                  {!!activeDragId && activeDragSection && (
                    <SectionRow
                      volumeId={draft.id}
                      section={activeDragSection}
                      groupedComplianceMatrix={groupedComplianceMatrix}
                      isDragging
                    />
                  )}
                </DragOverlay>
              </SortableContext>
              {!isImportStep && !isReadOnly && (
                <button
                  className="flex flex-row justify-center items-center text-xs gap-1 py-1 px-1.5 rounded-md ml-[62px] mt-1  duration-100 bg-black/80 backdrop-blur-lg text-white hover:bg-black"
                  onClick={handleAddSection}
                >
                  <Plus size={14} className="text-sm" />
                  Add Section
                </button>
              )}
            </DndContext>
          </Accordion.Content>
        </Accordion.Item>
      </div>
    );
  }
);

export default memo(DraftRow);
