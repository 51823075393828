import { highlightAndScrollToElement } from "./utils";
import { useSearchParams } from "react-router-dom";
import {
  setIsExtractingDocument,
  setTimeRemaining,
  getRequirementGroups,
  getExtractionDocuments,
} from "store/reducers/extract/CurrentExtractionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { InstantDraftStatus } from "components/copilot/CopilotSchemaTypes";
import useExtractionOperations from "hook/useExtractionOperations";
import { getAtlasRequirements } from "store/reducers/projectReducer";
import { isEqual } from "lodash";
import { LiveList } from "YJSProvider/LiveObjects";
import useGetRequirements from "hook/Requirements/useGetRequirements";
import { GenerationStatus } from "types/Requirement";

export const useGetRequirementGroups = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");

  useEffect(() => {
    if (projectId) dispatch(getRequirementGroups({ projectId, isInitialFetch: true }));
  }, [dispatch, projectId]);
};

export const useValidateExtractionStatus = () => {
  const dispatch = useAppDispatch();
  const create_document_view_tasks = useAppSelector((state) => state.autopilotHealthCheck.create_document_view_tasks);
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);

  const extractingTask = useMemo(() => {
    return create_document_view_tasks.find((task) => {
      return task?.reference_id && extraction?.file_ids.includes(task.reference_id);
    });
  }, [create_document_view_tasks, extraction?.file_ids]);
  const isExtractingDocument = !!extractingTask;

  const timeRemaining = useMemo(() => {
    const tasks = create_document_view_tasks.filter(
      (task) => task?.reference_id && extraction?.file_ids.includes(task.reference_id)
    );
    return tasks.length > 0
      ? Math.max(...tasks.map((task) => (task?.minutes_time_remaining ? task.minutes_time_remaining : 0)))
      : 0;
  }, [extraction?.file_ids, create_document_view_tasks]);

  useEffect(() => {
    dispatch(setIsExtractingDocument(isExtractingDocument));
  }, [dispatch, isExtractingDocument]);

  useEffect(() => {
    dispatch(setTimeRemaining(timeRemaining));
  }, [dispatch, timeRemaining]);

  useEffect(() => {
    if (extractingTask?.is_started && extraction?.file_ids) dispatch(getExtractionDocuments(extraction.file_ids));
  }, [dispatch, extractingTask?.is_started, extraction?.file_ids]);
};

export const useValidateInstantDraftStatusAndConfig = () => {
  const dispatch = useAppDispatch();
  const isSettingToDone = useRef(false);
  const { updateInstantDraftConfig, setInstantDraftCompleted } = useExtractionOperations();
  const requirementResponseTasks = useAppSelector((state) => state.autopilotHealthCheck.requirement_response_tasks);
  const outlineVolumes = useAppSelector((store) => store.currentExtractionState.currentExtraction?.framework.volumes);
  const extractionId = useAppSelector((store) => store.currentExtractionState.currentExtraction?.id);
  const instantDraftStatus = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.status
  );
  const { data: atlasRequirements, refetch } = useGetRequirements(
    { analysis_id: extractionId || "" },
    {
      enabled: !!extractionId,
      refetchInterval: () =>
        instantDraftStatus === InstantDraftStatus.Pending || instantDraftStatus === InstantDraftStatus.InProgress
          ? 5000
          : false,
    }
  );

  const submittedRequirements = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.submittedRequirements
  );

  const volumes = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.volumes || []
  );
  const sections = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.sections || []
  );

  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");

  const instantDraftTask = useMemo(() => {
    return requirementResponseTasks.find((task) => {
      return task.reference_id === extractionId;
    });
  }, [extractionId, requirementResponseTasks]);

  const setToDone = useCallback(
    async (extractionId: string, projectId: string) => {
      isSettingToDone.current = true;
      try {
        await dispatch(getAtlasRequirements(projectId));
        const { data: extractionRequirements = [] } = await refetch();

        const submittedAtlasRequirements = extractionRequirements
          .filter(({ id }) => submittedRequirements?.includes(id))
          .map(({ id, response }) => ({
            id,
            content: response?.content || "",
            sources:
              response?.sources?.map(({ file, citations }) => ({
                file_id: file.id,
                name: file.name,
                extension_type: file.type,
                date: file.created_at,
                used_file_contents: citations.map((citation) => ({
                  id: citation.file_content_id,
                  partition_order_key: citation.partition_order_key,
                  content: citation.content,
                  requirement_source_citations: citation.citation,
                })),
              })) || [],
          }));

        if (!submittedAtlasRequirements.length) return;
        console.log("--------- setting draft to done, writing responses --------");

        setInstantDraftCompleted(extractionId, submittedAtlasRequirements);
      } catch (err) {
      } finally {
        isSettingToDone.current = false;
      }
    },
    [dispatch, refetch, setInstantDraftCompleted, submittedRequirements]
  );

  const draftStatusMap = useMemo(() => {
    return atlasRequirements.length
      ? {
          draftStarted: atlasRequirements.some((req) => !!req.response?.generation_status),
          isInProgress:
            instantDraftTask?.completed_responses !== instantDraftTask?.total_responses ||
            atlasRequirements.some((req) => req.response?.generation_status === GenerationStatus.InProgress),
        }
      : null;
  }, [atlasRequirements, instantDraftTask?.completed_responses, instantDraftTask?.total_responses]);

  useEffect(() => {
    if (!draftStatusMap || !projectId || !extractionId || instantDraftStatus === InstantDraftStatus.Done) return;

    if (!draftStatusMap.draftStarted && instantDraftStatus !== InstantDraftStatus.Todo) {
      // set status to in progress
      console.log("--------- setting draft to to do --------");
      updateInstantDraftConfig(extractionId, { status: InstantDraftStatus.Todo });
      return;
    }

    if (
      draftStatusMap.isInProgress &&
      instantDraftStatus !== InstantDraftStatus.InProgress &&
      (instantDraftStatus === InstantDraftStatus.Todo || instantDraftStatus === InstantDraftStatus.Pending)
    ) {
      // set status to in progress
      console.log("--------- setting draft to in progress --------");
      updateInstantDraftConfig(extractionId, { status: InstantDraftStatus.InProgress });
      return;
    }

    if (draftStatusMap.draftStarted && !draftStatusMap.isInProgress && !isSettingToDone.current) {
      // set status to done
      setToDone(extractionId, projectId);
      return;
    }
  }, [draftStatusMap, extractionId, instantDraftStatus, projectId, setToDone, updateInstantDraftConfig]);

  useEffect(() => {
    if (!extractionId || instantDraftStatus === InstantDraftStatus.Done) return;

    if (!volumes.length && !sections.length) return;

    const outlineSections = outlineVolumes?.flatMap(({ sections }) => sections) || [];
    const newVolumes = volumes.filter((volumeId) => {
      return !!outlineVolumes?.some(({ id }) => volumeId === id);
    });
    const newSections = sections.filter((sectionId) => {
      return !!outlineSections?.some(({ id }) => sectionId === id);
    });

    if (!isEqual(newVolumes, volumes)) {
      updateInstantDraftConfig(extractionId, { volumes: new LiveList(newVolumes) });
    }

    if (!isEqual(newSections, sections)) {
      updateInstantDraftConfig(extractionId, { sections: new LiveList(newSections) });
    }
  }, [extractionId, instantDraftStatus, outlineVolumes, sections, updateInstantDraftConfig, volumes]);
};

export const useScrollToNodes = () => {
  const highlightedElementId = useAppSelector((root) => root.currentExtractionState.highlightedElementId);

  const scrollToNodes = useCallback(() => {
    highlightAndScrollToElement(highlightedElementId);
  }, [highlightedElementId]);

  useEffect(() => {
    scrollToNodes();
  }, [scrollToNodes]);

  return scrollToNodes;
};
