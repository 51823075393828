import Lottie from "lottie-react";
import GenerationLottie from "Assets/lotties/generation-lottie.json";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import { INSTANT_DRAFT_LOADING_MSGS } from "./constants";
import { useMemo, useState } from "react";
import { useAppSelector } from "store/storeTypes";

const InstantDraftLoading = () => {
  const [loadingStep, setLoadingStep] = useState(INSTANT_DRAFT_LOADING_MSGS[0]);
  useAnimateLoadingMsg(true, 15000, INSTANT_DRAFT_LOADING_MSGS, setLoadingStep);
  const requirementResponseTasks = useAppSelector((state) => state.autopilotHealthCheck.requirement_response_tasks);
  const extractionId = useAppSelector((store) => store.currentExtractionState.currentExtraction?.id);

  const instantDraftTask = useMemo(() => {
    return requirementResponseTasks.find((task) => {
      return task.reference_id === extractionId;
    });
  }, [extractionId, requirementResponseTasks]);

  const progressPercentage = useMemo(() => {
    if (
      typeof instantDraftTask?.completed_responses === "number" &&
      typeof instantDraftTask?.total_responses === "number"
    ) {
      return (instantDraftTask?.completed_responses / instantDraftTask?.total_responses) * 100;
    }
    return 0;
  }, [instantDraftTask]);

  return (
    <div className="h-full w-full gap-8 flex flex-col justify-center items-center px-14 pb-6">
      <div className="flex flex-col items-center">
        <Lottie className="h-[350px] w-[350px]" animationData={GenerationLottie} loop />
        <div className="text-xl font-medium">
          {loadingStep}
          <span className="loading-ellipsis" />
        </div>
      </div>
      <div className="bg-gray-200 relative overflow-hidden rounded h-1 w-1/3">
        <div
          className="absolute duration-700 rounded-full left-0 top-0 bottom-0 bg-gray-darkest"
          style={{ width: `${progressPercentage || 2}%` }}
        />
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-gray-500 text-sm text-center">
          {typeof instantDraftTask?.minutes_time_remaining === "number" ? (
            `This will take about ${instantDraftTask?.minutes_time_remaining || 1} minutes`
          ) : (
            <>
              Estimating time remaining
              <span className="loading-ellipsis" />
            </>
          )}
        </div>
        <div className="text-gray-500 text-sm text-center">
          Use this time to grab a coffee, relax, or start another task. <br />
          Your generation will continue running in the background even if you leave this page.
        </div>
      </div>
    </div>
  );
};

export default InstantDraftLoading;
