import { Extraction, ExtractionStatus, InstantDraftStatus } from "components/copilot/CopilotSchemaTypes";
import { StepValue } from "components/copilot/extract-v2/doc-viewer";
import { nanoid } from "nanoid";
import { ResponseTolerance } from "types/Requirement";
import { LiveList, LiveObject } from "YJSProvider/LiveObjects";

export const createExtraction = (properties?: Partial<Extraction>): LiveObject<Extraction> => {
  const newID = nanoid();
  return new LiveObject({
    id: newID,
    name: `Extraction_${newID.slice(0, 6)}`,
    status: ExtractionStatus.Extracting,
    file_ids: new LiveList([]),
    step: StepValue.Template,
    compliance_matrix: new LiveList([]),
    framework: new LiveObject({ volumes: new LiveList([]) }),
    created_at: new Date().toISOString(),
    instantDraftConfig: createInstantDraftConfig(),
    ...properties,
  });
};

export const createInstantDraftConfig = (
  properties?: Partial<Extraction["instantDraftConfig"]>
): LiveObject<NonNullable<Extraction["instantDraftConfig"]>> => {
  return new LiveObject({
    strictness: ResponseTolerance.Moderate,
    confirmations: new LiveList([]),
    volumes: new LiveList([]),
    sections: new LiveList([]),
    status: InstantDraftStatus.Todo,
    submittedRequirements: [],
    ...properties,
  });
};
