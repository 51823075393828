/** @jsxImportSource @emotion/react */

import { pdfjs } from "react-pdf";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import tw from "twin.macro";
import { Modal } from "components/organisms/modal";
import DocumentDisplay from "./document-display";
import DocumentSidebar from "./document-sidebar";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { clearExtractState } from "store/reducers/extract/ExtractReducerV2";
import { ScreenSpinner } from "utils/icons";
import StepManager from "./step-manager";
import { StepValue } from "./types";
import TemplateSelection from "./template-selection";
import { useGetCoordinates, useGetTemplates } from "../hooks";
import { getExtractionDocuments, setSectionToMove } from "store/reducers/extract/CurrentExtractionReducer";
import Import from "./import";
import {
  useGetRequirementGroups,
  useScrollToNodes,
  useValidateExtractionStatus,
  useValidateInstantDraftStatusAndConfig,
} from "./hooks";
import isEqual from "lodash/isEqual";
import keyBy from "lodash/keyBy";
import { REQUIREMENTS_FILTER_LAUNCH_DATE } from "const-values/DocView";
import DocViewNotificationsProvider from "../context/doc-view-notification-context/docViewNotificationContext";
import { Viewport } from "@radix-ui/react-toast";
import { ExtractionStatus, Section, Volume } from "components/copilot/CopilotSchemaTypes";
import MoveRequirementOrSectionModal, { TypeToMove } from "components/organisms/move-requirement-or-section-modal";
import { useNotification } from "context/notificationContext";
import { LiveList, ToImmutable } from "YJSProvider/LiveObjects";
import { createSection } from "utils/framework";
import useExtractionOperations from "hook/useExtractionOperations";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlagSet } from "types/FeatureFlagSet";
import InstantDraftGenerationForm from "./instant-draft-generation-form";
import ReviewResults from "./ReviewResults";
import { isInstantDraftStarted } from "./utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ExtractDocViewerModal = () => {
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const sectionToMove = useAppSelector((store) => store.currentExtractionState.sectionToMove);
  const templateRequirements = useAppSelector((store) => store.currentExtractionState.templateRequirements);
  const prevFileIds = useRef<string[] | undefined>(extraction?.file_ids);
  const { setToast } = useNotification();
  const isRequirementsStep = extraction?.step === StepValue.Requirements;
  const dispatch = useAppDispatch();
  const flags = useFlags<FeatureFlagSet>();
  const { addNewVolume, addNewSection, moveSection, insertContextIntoOutline, updateExtractionStep } =
    useExtractionOperations();
  useGetCoordinates();
  useGetTemplates();
  useValidateExtractionStatus();
  useValidateInstantDraftStatusAndConfig();
  useScrollToNodes();
  useGetRequirementGroups();

  useEffect(() => {
    if (!!extraction?.file_ids?.length && !isEqual(prevFileIds.current, extraction?.file_ids)) {
      dispatch(getExtractionDocuments(extraction?.file_ids));
    }
    prevFileIds.current = extraction?.file_ids;
  }, [dispatch, extraction?.file_ids]);

  useEffect(() => {
    return () => {
      dispatch(clearExtractState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (extraction?.step === StepValue.Generate && !flags.instantDraftFromGeneration) {
      updateExtractionStep(extraction.id, StepValue.Assign);
    }
  }, [extraction?.id, extraction?.step, flags.instantDraftFromGeneration, updateExtractionStep]);

  const isReadOnly =
    extraction?.status === ExtractionStatus.Completed || isInstantDraftStarted(extraction?.instantDraftConfig?.status);
  const isRequirementsOrAssign = extraction?.step === StepValue.Requirements || extraction?.step === StepValue.Assign;
  const shouldShowSidebar = useMemo(
    () =>
      extraction?.step === StepValue.Assign ||
      (isRequirementsStep &&
        !isReadOnly &&
        extraction.created_at &&
        new Date(extraction.created_at).getTime() >= new Date(REQUIREMENTS_FILTER_LAUNCH_DATE).getTime()),
    [extraction?.created_at, extraction?.step, isReadOnly, isRequirementsStep]
  );

  const filteredOutSectionToMove = useMemo(
    () =>
      extraction?.framework.volumes.map(({ sections, ...rest }) => ({
        ...rest,
        sections: sections.filter((section) => section.id !== sectionToMove?.id),
      })) || [],
    [extraction?.framework.volumes, sectionToMove?.id]
  );

  const shouldDisableSections = useMemo(
    () =>
      !!extraction?.framework.volumes
        .flatMap(({ sections }) => sections)
        .some((section) => sectionToMove?.id && section.parent_id === sectionToMove.id),
    [extraction?.framework.volumes, sectionToMove?.id]
  );

  const onSectionMove = useCallback(
    (selectedSectionOrVolume: ToImmutable<Section> | ToImmutable<Volume>) => {
      if (!extraction) return;
      const existsInBank = extraction.framework.context_bank
        ?.flatMap((volume) => volume.sections)
        ?.some((section) => section.id === sectionToMove?.id);
      const existsInOutline = extraction.framework.volumes
        ?.flatMap((volume) => volume.sections)
        ?.some((section) => section.id === sectionToMove?.id);
      const isContextual = existsInBank && !existsInOutline;

      if (sectionToMove) {
        if (isContextual) {
          const groupedTemplateRequirements = keyBy(templateRequirements, "id");

          const requirementIdsForAssignment = extraction?.compliance_matrix
            .filter(
              (yjsReq) =>
                !yjsReq.requirement.skipped &&
                !!groupedTemplateRequirements[yjsReq.requirement.id] &&
                groupedTemplateRequirements[yjsReq.requirement.id].section_id === sectionToMove.id &&
                (!yjsReq.proposal_reference.section_id || yjsReq.proposal_reference.section_id === sectionToMove.id)
            )
            .map((req) => req.requirement.id);

          insertContextIntoOutline(
            extraction.id,
            sectionToMove.id,
            selectedSectionOrVolume,
            requirementIdsForAssignment
          );
        } else moveSection(extraction.id, sectionToMove.id, selectedSectionOrVolume);
      }
      setToast.success({
        msg: (
          <div>
            <span className="font-semibold text-sm line-clamp-4">{sectionToMove?.title}</span> moved to{" "}
            <span className="font-semibold text-sm line-clamp-4">{selectedSectionOrVolume.title}</span>.
          </div>
        ),
      });

      dispatch(setSectionToMove(null));
    },
    [dispatch, extraction, insertContextIntoOutline, moveSection, sectionToMove, setToast, templateRequirements]
  );

  return (
    <Modal
      open
      hideClose
      contentProps={{
        onInteractOutside: (e) => e.preventDefault(),
        css: tw`p-0 rounded-none !w-screen !h-screen !min-w-[100vw] !min-h-[100vh]`,
      }}
      bodyProps={tw`m-0`}
      body={
        extraction ? (
          <DocViewNotificationsProvider>
            <MoveRequirementOrSectionModal
              canCreateDraft={false}
              searchBarChipLabel={sectionToMove?.title || "Section"}
              onMove={onSectionMove}
              onCreateOutline={(newSectionConfig) => {
                if (!newSectionConfig) return;
                const didFillOutSection = !!newSectionConfig.section?.title.trim();
                const selectedVolume = extraction.framework.volumes.find(
                  (volume) => volume.id === newSectionConfig.draft?.id
                );
                let newSectionOrVolume: ToImmutable<Section> | ToImmutable<Volume> | undefined = selectedVolume;

                if (!selectedVolume && didFillOutSection) {
                  const createdSection = createSection({
                    title: newSectionConfig.section?.title,
                  }).item;

                  addNewVolume(extraction.id, {
                    title: newSectionConfig.draft?.title,
                    sections: new LiveList([createdSection]),
                  });
                  newSectionOrVolume = createdSection.toJSON() as ToImmutable<Section>;
                } else if (!selectedVolume && !didFillOutSection) {
                  newSectionOrVolume = addNewVolume(extraction.id, {
                    title: newSectionConfig.draft?.title,
                  });
                } else if (selectedVolume && didFillOutSection) {
                  newSectionOrVolume = addNewSection(extraction.id, selectedVolume.id, {
                    title: newSectionConfig.section?.title,
                  });
                }

                if (newSectionOrVolume && newSectionConfig.assignToSection) {
                  if (sectionToMove) moveSection(extraction.id, sectionToMove.id, newSectionOrVolume);

                  setToast.success({
                    msg: (
                      <div>
                        <span className="font-semibold text-sm">{sectionToMove?.title}</span> moved to{" "}
                        <span className="font-semibold text-sm">{newSectionOrVolume.title}</span>.
                      </div>
                    ),
                  });
                }

                dispatch(setSectionToMove(null));
              }}
              type={TypeToMove.Section}
              volumes={filteredOutSectionToMove}
              open={!!sectionToMove}
              onOpenChange={(open) => !open && dispatch(setSectionToMove(null))}
              disabledState={{ subsections: true, sections: shouldDisableSections }}
            />
            <div className="flex flex-col h-full bg-layout-gray-light">
              <StepManager />
              <PanelGroup className="p-2" direction="horizontal">
                <Panel className="relative border border-gray-lightest rounded bg-white shadow">
                  {extraction.step === StepValue.Template && <TemplateSelection />}
                  {isRequirementsOrAssign && <DocumentDisplay />}
                  {extraction.step === StepValue.Generate && flags.instantDraftFromGeneration && (
                    <InstantDraftGenerationForm />
                  )}
                  {extraction.step === StepValue.Review && !flags.instantDraftFromGeneration && <Import />}
                  {extraction.step === StepValue.Review && !!flags.instantDraftFromGeneration && <ReviewResults />}
                  <Viewport className="absolute bottom-2 left-2 flex flex-col gap-2 max-w-[100vw] m-0 list-none z-[9] outline-none" />
                </Panel>
                {shouldShowSidebar && <PanelResizeHandle className="z-[1] w-2 relative group flex justify-center" />}
                {shouldShowSidebar && (
                  <Panel
                    className="border border-gray-lightest rounded bg-white shadow"
                    defaultSize={32}
                    minSize={22}
                    maxSize={55}
                  >
                    <DocumentSidebar />
                  </Panel>
                )}
              </PanelGroup>
            </div>
          </DocViewNotificationsProvider>
        ) : (
          <div className="flex h-full w-full items-center justify-center">
            <ScreenSpinner />
          </div>
        )
      }
    />
  );
};

export default memo(ExtractDocViewerModal);
