/** @jsxImportSource @emotion/react */

import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import * as Accordion from "@radix-ui/react-accordion";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { groupBy } from "lodash";
import { StepValue } from "../../types";
import { ReactComponent as EmptyTemplate } from "Assets/svgs/empty-template.svg";
import { Extraction, ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import {
  setActiveSection,
  setEditableTemplateRowState,
  setTemplateOpenState,
} from "store/reducers/extract/CurrentExtractionReducer";
import DraftRow, { SortableItem } from "./draft-row";
import useExtractionOperations from "hook/useExtractionOperations";
import { ChevronsDownUp, ChevronsUpDown, Plus } from "lucide-react";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { useDrag } from "./hooks";
import { verticalSortableListCollisionDetection } from "pages/draft-volume/draft-volume-sidebar/utils";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { ToImmutable } from "YJSProvider/LiveObjects";
import ContextBankManager from "./context-bank-manager";
import { isInstantDraftStarted } from "../../utils";

const TemplateManager = () => {
  const dispatch = useAppDispatch();
  const { addNewVolume } = useExtractionOperations();
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const openState = useAppSelector((store) => store.currentExtractionState.templateOpenState);
  const isReadOnly =
    extraction?.status === ExtractionStatus.Completed || isInstantDraftStarted(extraction?.instantDraftConfig?.status);
  const isAssignStep = extraction?.step === StepValue.Assign;
  const isImportStep = extraction?.step === StepValue.Review;
  const isTemplateStep = extraction?.step === StepValue.Template;
  const groupedComplianceMatrix: Record<string, ToImmutable<Extraction["compliance_matrix"]>> = useMemo(() => {
    if (!isAssignStep && !isImportStep) return {};
    return groupBy(
      extraction?.compliance_matrix.filter(
        (row) => !row.requirement.soft_deleted && !!row.proposal_reference.section_id && !row.requirement.skipped
      ) || [],
      (row) => row.proposal_reference.section_id
    );
  }, [extraction?.compliance_matrix, isAssignStep, isImportStep]);

  useEffect(() => {
    if (extraction?.step !== StepValue.Assign) dispatch(setActiveSection(undefined));
    return () => {
      if (extraction?.step === StepValue.Assign) dispatch(setActiveSection(undefined));
    };
  }, [dispatch, extraction?.step]);

  const formattedDrafts = useMemo(() => {
    const drafts = extraction?.framework?.volumes || [];

    return drafts.map((vol) => ({
      ...vol,
      sections:
        vol?.sections?.reduce<FormattedSection[]>((acc, section) => {
          if (!section.parent_id) {
            const subsections = vol?.sections?.filter(({ parent_id }) => parent_id === section.id) || [];
            return [...acc, { ...section, subsections }];
          }

          return acc;
        }, []) || [],
    }));
  }, [extraction?.framework?.volumes]);

  const { sensors, handleDragEnd, handleDragStart, handleDragCancel, activeDragId, dragDrafts } = useDrag(
    formattedDrafts,
    extraction?.id
  );

  const activeDraft = useMemo(() => dragDrafts.find((draft) => draft.id === activeDragId), [activeDragId, dragDrafts]);

  if (!extraction) return null;

  return (
    <>
      {!isImportStep && (
        <div className="border-b flex items-center justify-between border-gray-light p-3">
          <div className="font-semibold text-base">Created Outline</div>
          <div className="flex items-center gap-2.5">
            <Tooltip content="Collapse All">
              <button
                className="rounded-md p-1.5 border shadow-sharp-thin text-slate-900 duration-150 hover:brightness-90 hover:bg-slate-100"
                onClick={() => {
                  dispatch(setTemplateOpenState([""]));
                }}
              >
                <ChevronsDownUp size={14} />
              </button>
            </Tooltip>
            <Tooltip content="Expand All">
              <button
                className="rounded-md p-1.5 border shadow-sharp-thin text-slate-900 duration-150 hover:brightness-90 hover:bg-slate-100"
                onClick={() => {
                  const newState = (extraction?.framework || []).volumes.reduce<string[]>(
                    (acc, draft) => [...acc, draft.id, ...draft.sections.map(({ id }) => id)],
                    []
                  );
                  dispatch(setTemplateOpenState(newState));
                }}
              >
                <ChevronsUpDown size={14} />
              </button>
            </Tooltip>
            {!isImportStep && !isReadOnly && (
              <Tooltip content="New Volume">
                <button
                  className="rounded-md p-1.5 border shadow-sharp-thin text-slate-900 duration-150 hover:brightness-90 hover:bg-slate-100"
                  onClick={() => {
                    if (extraction?.id) {
                      const createdDraft = addNewVolume(extraction.id);
                      dispatch(setTemplateOpenState([...openState, createdDraft.id]));
                      setTimeout(
                        () =>
                          dispatch(
                            setEditableTemplateRowState({
                              localValue: createdDraft.title,
                              id: createdDraft.id,
                            })
                          ),
                        80
                      );
                    }
                  }}
                >
                  <Plus size={16} />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      )}
      <div className="flex flex-1 flex-col relative h-full overflow-hidden">
        <div className="flex-1 flex relative h-full overflow-hidden">
          <div className="flex flex-col gap-4 flex-1 p-3 pl-1 overflow-y-auto">
            {!formattedDrafts.length ? (
              <div className="flex flex-col h-full justify-center items-center gap-4">
                <EmptyTemplate />
                <div className="flex flex-col items-center text-xs text-gray-500">
                  <span>{isTemplateStep && "No templates imported."}</span>
                  <span>{(isAssignStep || isImportStep) && "No outline available."}</span>
                </div>
              </div>
            ) : (
              <DndContext
                sensors={sensors}
                collisionDetection={verticalSortableListCollisionDetection}
                onDragEnd={(event) => handleDragEnd(event)}
                onDragStart={handleDragStart}
                onDragCancel={handleDragCancel}
              >
                <div className="h-full">
                  <div className="relative pb-16">
                    <Accordion.Root
                      onValueChange={(state) => dispatch(setTemplateOpenState(state))}
                      value={openState}
                      type="multiple"
                    >
                      <SortableContext
                        id="EXTRACTION_TEMPLATE_DRAFTS"
                        items={dragDrafts || []}
                        strategy={verticalListSortingStrategy}
                      >
                        {dragDrafts?.map((draft) => (
                          <SortableItem
                            key={draft.id}
                            draft={draft}
                            groupedComplianceMatrix={groupedComplianceMatrix}
                          />
                        ))}
                        <DragOverlay style={{ transformOrigin: "0 0 " }}>
                          {!!activeDragId && activeDraft && (
                            <DraftRow
                              draft={activeDraft}
                              groupedComplianceMatrix={groupedComplianceMatrix}
                              isDragging
                            />
                          )}
                        </DragOverlay>
                      </SortableContext>
                    </Accordion.Root>
                  </div>
                </div>
              </DndContext>
            )}
          </div>
        </div>
        {!isImportStep && !!extraction.framework.context_bank?.length && <ContextBankManager />}
      </div>
    </>
  );
};

export default TemplateManager;
