import { useMemo } from "react";
import sortBy from "lodash/sortBy";
import { ReactComponent as Requirements } from "Assets/extract/requirements.svg";
import { ReactComponent as Template } from "Assets/extract/template.svg";
import { ReactComponent as Assign } from "Assets/extract/assign.svg";
import { ReactComponent as Generate } from "Assets/generate-v2.svg";
import { ReactComponent as Review } from "Assets/extract/generate.svg";
import { Step } from "components/organisms/stepper";
import { StepValue } from "../types";

export const useExtractionSteps = (
  instantDraftEnabled: boolean,
  currentStep?: StepValue,
  isExtractionCompleted?: boolean
) => {
  const groupedSteps: Record<StepValue, Step<StepValue>> = useMemo(
    () => ({
      [StepValue.Template]: {
        label: "Select templates",
        icon: Template,
        step: 1,
        value: StepValue.Template,
      },
      [StepValue.Requirements]: {
        label: "Select requirements",
        icon: Requirements,
        step: 2,
        value: StepValue.Requirements,
      },
      [StepValue.Assign]: {
        label: "Assign requirements",
        icon: Assign,
        step: 3,
        value: StepValue.Assign,
      },
      [StepValue.Generate]: {
        label: "Generate draft",
        icon: Generate,
        step: 4,
        value: StepValue.Generate,
      },
      [StepValue.Review]: {
        label: "Publish results",
        icon: Review,
        step: 5,
        value: StepValue.Review,
        disabled: currentStep === StepValue.Generate && !isExtractionCompleted,
      },
    }),
    [currentStep, isExtractionCompleted]
  );

  const extractSteps = useMemo(
    () =>
      sortBy(
        Object.values(groupedSteps).filter((step) =>
          step.value === StepValue.Generate && !instantDraftEnabled ? false : true
        ),
        "step"
      ),
    [groupedSteps, instantDraftEnabled]
  );

  return { groupedSteps, extractSteps };
};

export const useExtractionStepsToMeta = (instantDraftEnabled: boolean) => {
  const STEP_TO_META: Record<StepValue, { prevStep: StepValue | null; nextStep: StepValue | null }> = {
    [StepValue.Template]: {
      prevStep: null,
      nextStep: StepValue.Requirements,
    },
    [StepValue.Requirements]: {
      prevStep: StepValue.Template,
      nextStep: StepValue.Assign,
    },
    [StepValue.Assign]: {
      prevStep: StepValue.Requirements,
      nextStep: instantDraftEnabled ? StepValue.Generate : StepValue.Review,
    },
    [StepValue.Generate]: {
      prevStep: StepValue.Assign,
      nextStep: StepValue.Review,
    },
    [StepValue.Review]: {
      prevStep: instantDraftEnabled ? StepValue.Generate : StepValue.Assign,
      nextStep: null,
    },
  };

  return STEP_TO_META;
};
