import { createSheet } from "utils/sheet";
import { getSectionsMap } from "utils/Liveblocks/Framework";
import { nanoid } from "nanoid";
import { setActiveSection } from "store/reducers/extract/CurrentExtractionReducer";
import { StepValue } from "components/copilot/extract-v2/doc-viewer";
import { useCallback } from "react";
import { useMutation } from "YJSProvider/createYJSContext";
import {
  ComplianceMatrixRow,
  Extraction,
  ExtractionFramework,
  ExtractionStatus,
  Framework,
  InstantDraftConfig,
  InstantDraftStatus,
  Section,
  Storage,
  Volume,
} from "components/copilot/CopilotSchemaTypes";
import { createSection, createVolume } from "utils/framework";
import { filter, find, findIndex, LiveList, LiveObject, some, ToImmutable, update } from "YJSProvider/LiveObjects";
import { useAppDispatch, useAppSelector } from "store/storeTypes";

import useSheetOperations from "./useSheetOperations";
import { createComplianceMatrixRow } from "utils/complianceMatrix";
import { createInstantDraftConfig } from "utils/extraction";
import uniq from "lodash/uniq";
import pullAll from "lodash/pullAll";
import { RequirementStatus } from "components/copilot/CopilotSchemaImmutableTypes";

const getExtraction = (storage: LiveObject<Storage>, id: string): LiveObject<Extraction> | undefined => {
  const extractions = storage.get("extractions") as Storage["extractions"] | undefined;
  if (!extractions) return;
  return find(extractions, (extraction) => extraction.get("id") === id);
};
const useExtractionOperations = () => {
  const activeSection = useAppSelector((store) => store.currentExtractionState.activeSection);
  const documents = useAppSelector((store) => store.currentExtractionState.documents);
  const { appendNewSheets } = useSheetOperations();
  const dispatch = useAppDispatch();

  const appendExtraction = useMutation(({ storage }, extraction: LiveObject<Extraction>): string => {
    const extractions = (storage as LiveObject<Storage>).get("extractions");

    if (!!extractions?.length) {
      extractions.push([extraction]);
    } else {
      storage.set("extractions", new LiveList([extraction]));
    }

    return extraction.get("id");
  }, []);

  const updateExtractionStep = useMutation(({ storage }, extractionId: string, step: StepValue) => {
    const extraction = getExtraction(storage, extractionId);
    extraction?.set("step", step);
  }, []);

  const addNewVolume = useMutation(
    ({ storage }, extractionId: string, volumeProperties?: Partial<Volume>): ToImmutable<Volume> => {
      const extraction = getExtraction(storage, extractionId);
      const newVolume = createVolume(volumeProperties);

      extraction?.get("framework").get("volumes")?.push([newVolume]);

      return newVolume.toJSON() as ToImmutable<Volume>;
    },
    []
  );

  // Commenting this out until we add autoassign requirements
  // const deleteAllVolumes = useMutation(
  //     ({ storage }, extractionId: string, generatedTemplates: ExtractionTemplate[]) => {
  //         const extraction = (storage as LiveObject<Storage>)
  //             .get("extractions")
  //             ?.find((extraction) => extraction.get("id") === extractionId);
  //         const liveReqs = extraction?.get("compliance_matrix");

  //         const volumes = extraction?.get("framework")?.get("volumes");

  //         liveReqs?.forEach((row) => {
  //             const assignedVolumeId = row.get("proposal_reference")?.get("volume_id");
  //             if (!assignedVolumeId) {
  //                 row.get("proposal_reference").update({
  //                     volume_id: "",
  //                     section_id: "",
  //                     subsection_id: "",
  //                 });
  //             }
  //         });

  //         volumes?.clear();
  //     },
  //     []
  // );

  const addNewSection = useMutation(
    (
      { storage },
      extractionId: string,
      volumeId: string,
      sectionProperties?: Partial<Section>
    ): ToImmutable<Section> => {
      const extraction = getExtraction(storage, extractionId);
      let foundVolume: LiveObject<Volume> | undefined;

      if (extraction) {
        const volumes = extraction.get("framework").get("volumes") as Framework["volumes"] | undefined;
        if (volumes) {
          foundVolume = find(volumes, (vol) => vol.get("id") === volumeId);
        }
      }

      const { item: newSection } = createSection(sectionProperties);
      foundVolume?.get("sections")?.push([newSection]);

      return newSection.toJSON() as ToImmutable<Section>;
    },
    []
  );

  const appendExtractionDrafts = useMutation(({ storage }, extractionId: string, drafts: LiveObject<Volume>[]) => {
    const extractions = (storage as LiveObject<Storage>).get("extractions") as Storage["extractions"] | undefined;
    let extraction: LiveObject<Extraction> | undefined;
    if (extractions) {
      extraction = find(extractions, (extraction) => extraction.get("id") === extractionId);
    }
    if (extraction) validateInProgress(extraction.get("id"), extraction?.get("status"));
    drafts.forEach((draft) => extraction?.get("framework").get("volumes").push([draft]));
  }, []);

  const appendExtractionDraftsToContextBank = useMutation(
    ({ storage }, extractionId: string, drafts: LiveObject<Volume>[]) => {
      const extractions = storage.get("extractions") as Storage["extractions"] | undefined;
      if (!extractions) return;
      const extraction = find(extractions, (extraction) => extraction.get("id") === extractionId);
      if (!extraction) return;
      if (!extraction?.get("framework").get("context_bank"))
        extraction?.get("framework").set("context_bank", new LiveList([]));

      validateInProgress(extraction.get("id"), extraction?.get("status"));
      drafts.forEach((draft) => extraction?.get("framework").get("context_bank").push([draft]));
    },
    []
  );

  const setExtractionRequirementAssignees = useMutation(
    (
      { storage },
      extractionId: string,
      reqId: string,
      assignees: ToImmutable<ComplianceMatrixRow>["assigned_user_ids"]
    ) => {
      const extraction = getExtraction(storage, extractionId);
      const liveRequirements = extraction?.get("compliance_matrix") as
        | LiveList<LiveObject<ComplianceMatrixRow>>
        | undefined;
      let liveRequirement: LiveObject<ComplianceMatrixRow> | undefined;
      if (liveRequirements) {
        liveRequirement = find(liveRequirements, (row) => reqId === row.get("requirement").get("id"));
      }

      if (extraction) validateInProgress(extraction.get("id"), extraction?.get("status"));
      liveRequirement?.set("assigned_user_ids", assignees);
    },
    []
  );

  const setBulkExtractionRequirementsSkipped = useMutation(
    ({ storage }, extractionId: string, reqIds: string[], skipped: boolean) => {
      const extraction = getExtraction(storage, extractionId);
      const liveRequirements = extraction?.get("compliance_matrix") as
        | LiveList<LiveObject<ComplianceMatrixRow>>
        | undefined;
      liveRequirements?.forEach((row) => {
        const foundReqIncluded = reqIds.includes(row.get("requirement").get("id"));

        if (foundReqIncluded) {
          row?.get("requirement")?.set("skipped", skipped);
        }
      });

      if (extraction) validateInProgress(extraction.get("id"), extraction?.get("status"));
    },
    []
  );

  const unmergeRequirement = useMutation(
    ({ storage }, extractionId: string, mergedReqId: string, softDeletedReqIds: string[]) => {
      const extraction = getExtraction(storage, extractionId);
      const liveRequirements = extraction?.get("compliance_matrix") as
        | LiveList<LiveObject<ComplianceMatrixRow>>
        | undefined;

      if (!liveRequirements) return;

      const liveMergedRequirement = find(liveRequirements, (row) => mergedReqId === row.get("requirement").get("id"));
      const liveMergedRequirementSkipped = liveMergedRequirement?.get("requirement")?.get("skipped");
      const liveMergedRequirementAssignment = liveMergedRequirement?.get("proposal_reference");
      const liveSoftDeletedRequirements = filter(liveRequirements, (row) =>
        softDeletedReqIds.includes(row.get("requirement").get("id"))
      );

      liveSoftDeletedRequirements.forEach((softDeletedRequirement) => {
        update(softDeletedRequirement.get("requirement"), {
          skipped: liveMergedRequirementSkipped,
          soft_deleted: undefined,
        });
        update(softDeletedRequirement.get("proposal_reference"), {
          volume_id: liveMergedRequirementAssignment?.get("volume_id") || "",
          section_id: liveMergedRequirementAssignment?.get("section_id") || "",
        });
      });

      const liveMergedRequirementIndex = findIndex(
        liveRequirements,
        (row) => mergedReqId === row.get("requirement").get("id")
      );

      liveRequirements.delete(liveMergedRequirementIndex);
    },
    []
  );

  const setBulkExtractionRequirementsMerged = useMutation(({ storage }, extractionId: string, reqIds: string[]) => {
    const extraction = getExtraction(storage, extractionId);
    const liveRequirements = extraction?.get("compliance_matrix") as
      | LiveList<LiveObject<ComplianceMatrixRow>>
      | undefined;

    if (!liveRequirements) return;

    const mergeLiveRequirements = filter(liveRequirements, (row) => reqIds.includes(row.get("requirement").get("id")));
    mergeLiveRequirements?.forEach((row) => {
      row?.get("requirement")?.set("soft_deleted", true);
      update(row.get("proposal_reference"), {
        volume_id: "",
        section_id: "",
      });
    });

    if (extraction) validateInProgress(extraction.get("id"), extraction?.get("status"));
  }, []);

  const setExtractionRequirementSkipped = useMutation(
    ({ storage }, extractionId: string, reqId: string, skipped: boolean) => {
      const extraction = getExtraction(storage, extractionId);
      const liveRequirements = extraction?.get("compliance_matrix");
      let liveRequirement: LiveObject<ComplianceMatrixRow> | undefined;
      if (liveRequirements) {
        liveRequirement = find(liveRequirements, (row) => reqId === row.get("requirement").get("id"));
      }

      if (extraction) validateInProgress(extraction.get("id"), extraction?.get("status"));
      liveRequirement?.get("requirement")?.set("skipped", skipped);
    },
    []
  );

  const bulkUnassignExtractionRequirements = useMutation(({ storage }, extractionId: string, reqIds: string[]) => {
    const extraction = getExtraction(storage, extractionId);
    const matrix = extraction?.get("compliance_matrix") as LiveList<LiveObject<ComplianceMatrixRow>> | undefined;
    if (!matrix) return;
    const liveRequirements = filter(matrix, (row) => reqIds.includes(row.get("requirement").get("id")));
    liveRequirements?.forEach((row) => {
      const proposalReference = row?.get("proposal_reference");
      if (proposalReference) update(proposalReference, { section_id: "", volume_id: "" });
    });
  }, []);

  const bulkAssignExtractionRequirements = useMutation(
    ({ storage }, extractionId: string, reqIds: string[], sectionId: string) => {
      const extraction = getExtraction(storage, extractionId);

      const volumes = extraction?.get("framework")?.get("volumes") as LiveList<LiveObject<Volume>> | undefined;
      if (!volumes) return;
      const foundDraft = find(volumes, (draft) => {
        const sections = draft.get("sections") as LiveList<LiveObject<Section>> | undefined;
        if (!sections) return false;
        return some(sections, (sec) => sec.get("id") === sectionId);
      });
      const matrix = extraction?.get("compliance_matrix") as LiveList<LiveObject<ComplianceMatrixRow>> | undefined;
      if (!matrix) return;
      const liveRequirements = filter(matrix, (row) => reqIds.includes(row.get("requirement").get("id")));
      if (!foundDraft) return;

      liveRequirements?.forEach((row) => {
        const proposalReference = row?.get("proposal_reference");
        if (proposalReference) update(proposalReference, { section_id: sectionId, volume_id: foundDraft?.get("id") });
      });
    },
    []
  );

  const assignExtractionRequirement = useMutation(
    (
      { storage },
      extractionId: string,
      reqId: string,
      section_id: string
    ): ToImmutable<LiveObject<ComplianceMatrixRow>> | undefined => {
      const extraction = getExtraction(storage, extractionId);
      const volumes = extraction?.get("framework")?.get("volumes") as LiveList<LiveObject<Volume>> | undefined;
      let foundDraft: LiveObject<Volume> | undefined;
      if (volumes) {
        foundDraft = find(volumes, (draft) => {
          const sections = draft.get("sections") as LiveList<LiveObject<Section>> | undefined;
          if (!sections) return false;
          return some(sections, (sec) => sec.get("id") === section_id);
        });
      }
      const liveRequirements = extraction?.get("compliance_matrix") as
        | LiveList<LiveObject<ComplianceMatrixRow>>
        | undefined;
      let liveRequirement: LiveObject<ComplianceMatrixRow> | undefined;
      if (liveRequirements) {
        liveRequirement = find(liveRequirements, (row) => reqId === row.get("requirement").get("id"));
      }
      const liveProposalRef = liveRequirement?.get("proposal_reference") as
        | ComplianceMatrixRow["proposal_reference"]
        | undefined;

      if (extraction) validateInProgress(extraction.get("id"), extraction?.get("status"));
      if (liveProposalRef?.get("section_id") === section_id && liveProposalRef) {
        update(liveProposalRef, { section_id: "", volume_id: "" });
      } else if (liveProposalRef) {
        update(liveProposalRef, { volume_id: foundDraft?.get("id"), section_id });
      }
      return liveRequirement?.toJSON() as ToImmutable<LiveObject<ComplianceMatrixRow>>;
    },
    []
  );

  const setComplianceStatus = useMutation(
    (
      { storage },
      extractionId: string,
      reqId: string,
      status: ToImmutable<ComplianceMatrixRow>["compliance_status"]
    ) => {
      const extraction = getExtraction(storage, extractionId);
      const liveRequirements = extraction?.get("compliance_matrix");
      let liveRequirement: LiveObject<ComplianceMatrixRow> | undefined;
      if (liveRequirements)
        liveRequirement = find(liveRequirements, (row) => reqId === row.get("requirement").get("id"));

      if (extraction) validateInProgress(extraction.get("id"), extraction?.get("status"));
      liveRequirement?.set("compliance_status", status);
    },
    []
  );

  const createAndInsertRequirement = useMutation(
    ({ storage }, extractionId: string, insertIndex: number, partialRowProperties?: Partial<ComplianceMatrixRow>) => {
      const extraction = getExtraction(storage, extractionId);
      const liveRequirements = extraction?.get("compliance_matrix") as
        | LiveList<LiveObject<ComplianceMatrixRow>>
        | undefined;

      const newRow = createComplianceMatrixRow(partialRowProperties);
      liveRequirements?.insert(insertIndex, [newRow]);

      if (extraction) validateInProgress(extraction.get("id"), extraction?.get("status"));
    },
    []
  );

  const setExtractionName = useMutation(({ storage }, extractionId: string, name: ToImmutable<Extraction>["name"]) => {
    const extractions = (storage as LiveObject<Storage>).get("extractions") as Storage["extractions"] | undefined;
    if (!extractions) return;

    const extraction = find(extractions, (extraction) => extraction.get("id") === extractionId);

    extraction?.set("name", name);
  }, []);

  const setExtractionErrorReason = useMutation(
    ({ storage }, extractionId: string, errorReason: ToImmutable<Extraction>["error_reason"]) => {
      const extraction = getExtraction(storage, extractionId);
      extraction?.set("error_reason", errorReason);
    },
    []
  );

  const setExtractionStatus = useMutation(
    ({ storage }, extractionId: string, status: ToImmutable<Extraction>["status"]) => {
      const extraction = getExtraction(storage, extractionId);
      extraction?.set("status", status);
    },
    []
  );

  const setSectionName = useMutation(
    ({ storage }, extractionId: string, draftId: string, sectionId: string, name: string) => {
      const extraction = getExtraction(storage, extractionId);
      const volumes = extraction?.get("framework")?.get("volumes") as LiveList<LiveObject<Volume>> | undefined;
      let liveDraft: LiveObject<Volume> | undefined;
      if (volumes) {
        liveDraft = find(volumes, (draft) => draft.get("id") === draftId);
      }
      let liveSection: LiveObject<Section> | undefined;
      if (liveDraft) {
        const sections = liveDraft.get("sections") as LiveList<LiveObject<Section>> | undefined;
        if (sections) {
          liveSection = find(sections, (section) => section.get("id") === sectionId);
        }
      }

      liveSection?.set("title", name);
    },
    []
  );

  const setSectionPageCount = useMutation(({ storage }, extractionId: string, sectionId: string, pageCount: number) => {
    const extraction = getExtraction(storage, extractionId);
    const sections = (extraction?.get("framework")?.get("volumes") as LiveList<LiveObject<Volume>> | undefined)
      ?.toArray()
      ?.flatMap((volume) => volume.get("sections").toArray()) as LiveObject<Section>[];

    sections?.forEach((section) => {
      if (section.get("id") === sectionId) {
        section.set("draft_page_count", pageCount);
      }
    });
  }, []);

  const setDraftName = useMutation(({ storage }, extractionId: string, draftId: string, name: string) => {
    const extraction = getExtraction(storage, extractionId);
    const volumes = extraction?.get("framework")?.get("volumes") as LiveList<LiveObject<Volume>> | undefined;
    let liveDraft: LiveObject<Volume> | undefined;
    if (volumes) {
      liveDraft = find(volumes, (draft) => draft.get("id") === draftId);
    }
    liveDraft?.set("title", name);
  }, []);

  const deleteContextualDraft = useMutation(
    ({ storage }, extractionId: string, volumeId: string) => {
      const extraction = getExtraction(storage, extractionId);
      const volumeList = extraction?.get("framework")?.get("context_bank") as LiveList<LiveObject<Volume>> | undefined;
      if (!volumeList) return;
      const index: number | undefined = findIndex(volumeList, (v) => v.get("id") === volumeId);

      if (typeof index !== "number" || index === -1) return;

      volumeList?.delete(index);
    },
    [activeSection?.id, dispatch]
  );

  const deleteDraft = useMutation(
    ({ storage }, extractionId: string, volumeId: string) => {
      const extraction = getExtraction(storage, extractionId);
      const liveReqs = extraction?.get("compliance_matrix") as LiveList<LiveObject<ComplianceMatrixRow>> | undefined;
      const volumeList = extraction?.get("framework")?.get("volumes") as LiveList<LiveObject<Volume>> | undefined;
      let index: number | undefined;
      if (volumeList) {
        index = findIndex(volumeList, (v) => v.get("id") === volumeId);
      }

      if (typeof index !== "number" || index === -1) return;

      const liveVolume = volumeList?.get(index);

      liveReqs?.forEach((row) => {
        const assignedVolumeId = row.get("proposal_reference")?.get("volume_id");
        if (assignedVolumeId === volumeId) {
          const proposalReference = row.get("proposal_reference");
          if (proposalReference) {
            update(proposalReference, {
              volume_id: "",
              section_id: "",
              subsection_id: "",
            });
          }
        }
      });

      const sections = liveVolume?.get("sections") as LiveList<LiveObject<Section>> | undefined;
      if (sections && some(sections, (sec) => sec.get("id") === activeSection?.id)) {
        dispatch(setActiveSection(undefined));
      }

      volumeList?.delete(index);
    },
    [activeSection?.id, dispatch]
  );

  const deleteExtraction = useMutation(({ storage }, extractionId: string) => {
    const extractions = (storage as LiveObject<Storage>).get("extractions") as Storage["extractions"] | undefined;
    if (!extractions) return;

    const extractionIndex = findIndex(extractions, (extraction) => extraction.get("id") === extractionId);

    if (extractionIndex === -1) return;

    extractions.delete(extractionIndex);
  }, []);

  const deleteSection = useMutation(
    ({ storage }, extractionId: string, volumeId: string, sectionId: string) => {
      const extraction = getExtraction(storage, extractionId);

      const liveReqs = extraction?.get("compliance_matrix") as LiveList<LiveObject<ComplianceMatrixRow>> | undefined;
      const volumeList = extraction?.get("framework")?.get("volumes") as LiveList<LiveObject<Volume>> | undefined;
      let volumeIndex: number | undefined;
      if (volumeList) {
        volumeIndex = findIndex(volumeList, (volume) => volume.get("id") === volumeId);
      }
      if (typeof volumeIndex !== "number" || volumeIndex === -1) return;

      const sectionList = volumeList?.get(volumeIndex)?.get("sections") as LiveList<LiveObject<Section>> | undefined;

      sectionList?.forEach((sec) => {
        const id = sec.get("id");
        const parentId = sec.get("parent_id");
        const foundSectionOrSubsection = id === sectionId || parentId === sectionId;

        if (foundSectionOrSubsection) {
          liveReqs?.forEach((row) => {
            const assignedSectionId = row.get("proposal_reference")?.get("section_id");
            if (assignedSectionId === id) {
              const proposalReference = row.get("proposal_reference");
              if (proposalReference)
                update(proposalReference, {
                  volume_id: "",
                  section_id: "",
                  subsection_id: "",
                });
            }
          });

          let sectionIndex: number | undefined;
          if (sectionList) {
            sectionIndex = findIndex(sectionList, (section) => section.get("id") === id);
          }
          if (sectionIndex === -1) return;

          if (sec.get("id") === activeSection?.id) {
            dispatch(setActiveSection(undefined));
          }

          if (sectionList && typeof sectionIndex === "number") {
            sectionList.delete(sectionIndex);
          }
        }
      });

      return sectionList?.toJSON() as ToImmutable<LiveList<LiveObject<Section>>>;
    },
    [activeSection, dispatch]
  );

  const validateInProgress = useCallback(
    (extractionId: string, currentStatus: ExtractionStatus) => {
      if (currentStatus === ExtractionStatus.Ready) setExtractionStatus(extractionId, ExtractionStatus.InProgress);
    },
    [setExtractionStatus]
  );

  const moveSection = useMutation(
    (
      { storage },
      extractionId: string,
      sectionIdToMove: string,
      selectedSectionOrVolume: ToImmutable<Section> | ToImmutable<Volume>
    ) => {
      const extraction = getExtraction(storage, extractionId);
      const liveRequirements = extraction?.get("compliance_matrix") as
        | LiveList<LiveObject<ComplianceMatrixRow>>
        | undefined;

      const volumes = extraction?.get("framework")?.get("volumes") as ExtractionFramework["volumes"];

      const isSelectedVolume = "sections" in selectedSectionOrVolume;
      if (!volumes) return;
      const selectedVolume = find(volumes, (draft) => {
        if (isSelectedVolume) return draft.get("id") === selectedSectionOrVolume.id;
        const sections = draft.get("sections") as LiveList<LiveObject<Section>> | undefined;
        if (!sections) return false;
        return some(sections, (sec) => sec.get("id") === selectedSectionOrVolume.id);
      });
      const foundSelectedVolumeSections = selectedVolume?.get("sections") as LiveList<LiveObject<Section>> | undefined;

      if (!foundSelectedVolumeSections) return;
      const selectedSection = find(foundSelectedVolumeSections, (section) => {
        return section.get("id") === selectedSectionOrVolume.id;
      });
      const foundVolume = find(volumes, (draft) => {
        const sections = draft.get("sections") as LiveList<LiveObject<Section>> | undefined;
        if (!sections) return false;
        return some(sections, (sec) => sec.get("id") === sectionIdToMove);
      });

      const foundVolumeSections = foundVolume?.get("sections") as LiveList<LiveObject<Section>> | undefined;
      if (!foundVolumeSections) return;
      const foundSection = find(foundVolumeSections, (section) => {
        return section.get("id") === sectionIdToMove;
      });
      if (!foundSection) return;
      const subsectionsOfFoundSection = filter(foundVolumeSections, (section) => {
        return section.get("parent_id") === foundSection.get("id");
      });
      const assignedRequirements = liveRequirements
        ? filter(liveRequirements, (row) => {
            const assignedRowSectionId = row.get("proposal_reference").get("section_id");
            const isAssignedToSubsectionOfFoundSection = subsectionsOfFoundSection.some(
              (subsection) => subsection.get("id") === assignedRowSectionId
            );

            return isAssignedToSubsectionOfFoundSection || assignedRowSectionId === foundSection?.get("id");
          })
        : [];

      if (!foundSection) return;
      const sectionsAndSubsections = [foundSection, ...subsectionsOfFoundSection];

      assignedRequirements.forEach((row) => row.get("proposal_reference").set("volume_id", selectedVolume?.get("id")));

      sectionsAndSubsections.forEach((section) => {
        const clonedSection = section.clone();
        selectedVolume?.get("sections").push([clonedSection]);

        // moving a single section into a section
        if (selectedSection) {
          clonedSection.set("parent_id", selectedSection.get("id"));
        }

        // moving a single subsection into a volume
        if (!selectedSection && !!clonedSection.get("parent_id") && !!foundSection.get("parent_id")) {
          clonedSection.set("parent_id", "");
        }
      });

      sectionsAndSubsections.forEach((oldSection) => {
        const foundSectionIdx = findIndex(foundVolumeSections, (foundVolumeSection) => {
          return oldSection.get("id") === foundVolumeSection.get("id");
        });

        foundVolumeSections.delete(foundSectionIdx);
      });
    },
    []
  );

  const insertContextIntoOutline = useMutation(
    (
      { storage },
      extractionId: string,
      sectionIdToMove: string,
      selectedSectionOrVolume: ToImmutable<Section> | ToImmutable<Volume>,
      requirementIdsForAssignment: string[]
    ) => {
      const extraction = getExtraction(storage, extractionId);
      const liveRequirements = extraction?.get("compliance_matrix") as
        | LiveList<LiveObject<ComplianceMatrixRow>>
        | undefined;

      const requirementsToBeAssigned = liveRequirements
        ? filter(liveRequirements, (row) => requirementIdsForAssignment.includes(row.get("requirement").get("id")))
        : [];

      const contextBank = extraction?.get("framework")?.get("context_bank") as ExtractionFramework["context_bank"];
      const volumes = extraction?.get("framework")?.get("volumes") as ExtractionFramework["volumes"];

      const isSelectedVolume = "sections" in selectedSectionOrVolume;
      if (!contextBank) return;

      const selectedVolume = find(volumes, (draft) => {
        if (isSelectedVolume) return draft.get("id") === selectedSectionOrVolume.id;
        const sections = draft.get("sections") as LiveList<LiveObject<Section>> | undefined;
        if (!sections) return false;
        return some(sections, (sec) => sec.get("id") === selectedSectionOrVolume.id);
      });
      const foundSelectedVolumeSections = selectedVolume?.get("sections") as LiveList<LiveObject<Section>> | undefined;

      if (!foundSelectedVolumeSections) return;
      const selectedSection = find(foundSelectedVolumeSections, (section) => {
        return section.get("id") === selectedSectionOrVolume.id;
      });
      const foundVolume = find(contextBank, (draft) => {
        const sections = draft.get("sections") as LiveList<LiveObject<Section>> | undefined;
        if (!sections) return false;
        return some(sections, (sec) => sec.get("id") === sectionIdToMove);
      });

      const foundVolumeSections = foundVolume?.get("sections") as LiveList<LiveObject<Section>> | undefined;
      if (!foundVolumeSections) return;
      const foundSection = find(foundVolumeSections, (section) => {
        return section.get("id") === sectionIdToMove;
      });
      if (!foundSection) return;

      const clonedSection = foundSection.clone();
      selectedVolume?.get("sections").push([clonedSection]);
      requirementsToBeAssigned.forEach((row) =>
        update(row.get("proposal_reference"), {
          volume_id: selectedVolume?.get("id"),
          section_id: clonedSection.get("id"),
        })
      );

      // moving a single section into a section
      if (selectedSection) {
        clonedSection.set("parent_id", selectedSection.get("id"));
      }
    },
    []
  );

  const importExtraction = useMutation(
    ({ storage }, extractionId: string) => {
      const extraction = getExtraction(storage, extractionId);
      const liveMatrix = (storage as LiveObject<Storage>).get("compliance_matrix");
      const liveFramework = (storage as LiveObject<Storage>).get("framework");
      const sectionsMap = getSectionsMap(extraction?.get("framework")?.get("volumes").toJSON());

      if (!documents.length) return;

      const documentIdToSheetIdMap: Record<string, string> = {};
      const createdSheets = documents.map((document) => {
        const sheetID = nanoid();
        const sheet = createSheet({ name: document.file_name }, sheetID);
        documentIdToSheetIdMap[document.id] = sheetID;
        return sheet;
      });
      appendNewSheets(createdSheets);

      const matrix = extraction?.get("compliance_matrix") as LiveList<LiveObject<ComplianceMatrixRow>> | undefined;
      const matrixToArray = matrix?.toArray() || [];
      matrixToArray?.forEach((row) => {
        if (row.get("requirement").get("soft_deleted")) return;
        const sectionId = row.get("proposal_reference").get("section_id");
        if (!!sectionId && !sectionsMap[sectionId]) {
          update(row.get("proposal_reference"), { section_id: "", volume_id: "" });
        }
        const clonedRow = row.clone();
        liveMatrix?.push([clonedRow]);
        const extractionId = documentIdToSheetIdMap[clonedRow.get("document")?.get("id") || ""];
        clonedRow.get("requirement").set("extraction_id", extractionId);
      });

      const volumes = extraction?.get("framework")?.get("volumes") as LiveList<LiveObject<Volume>> | undefined;

      volumes?.forEach((draft) => {
        const clonedDraft = draft.clone();
        liveFramework?.get("volumes")?.push([clonedDraft]);
      });

      extraction?.set("status", ExtractionStatus.Completed);

      return true;
    },
    [documents, appendNewSheets]
  );

  const updateInstantDraftConfig = useMutation(
    ({ storage }, extractionId: string, config: Partial<InstantDraftConfig>) => {
      if (!config) return;
      const extraction = getExtraction(storage, extractionId);

      if (!extraction?.get("instantDraftConfig")) {
        extraction?.set("instantDraftConfig", createInstantDraftConfig());
      }
      const liveConfig = extraction?.get("instantDraftConfig");
      update(liveConfig, config);
    },
    [activeSection?.id, dispatch]
  );

  const setInstantDraftCompleted = useMutation(
    async (
      { storage },
      extractionId: string,
      submittedAtlasRequirements: {
        id: string;
        content: string | null;
        sources: ToImmutable<ComplianceMatrixRow["response_sources"]>;
      }[]
    ) => {
      const extraction = getExtraction(storage, extractionId);
      const matrix = extraction?.get("compliance_matrix") as LiveList<LiveObject<ComplianceMatrixRow>> | undefined;
      if (!matrix) return;

      matrix.forEach((row) => {
        const submittedAtlasRequirement = submittedAtlasRequirements.find(
          ({ id }) => id === row.get("requirement").get("id")
        );

        if (submittedAtlasRequirement) {
          const transformedLiveSources = submittedAtlasRequirement.sources?.map((source) => new LiveObject(source));

          update(row, {
            requirement_status: submittedAtlasRequirement.content?.trim()
              ? RequirementStatus.InProgress
              : RequirementStatus.Todo,
            written_content: submittedAtlasRequirement.content || "",
            response_sources: new LiveList(transformedLiveSources || []),
          });
        }
      });

      extraction?.get("instantDraftConfig")?.set("status", InstantDraftStatus.Done);

      extraction?.set("step", StepValue.Review);
    },
    []
  );

  const toggleInstantDraftOutline = useMutation(
    (
      { storage },
      extractionId: string,
      operation: "insert" | "remove",
      config?: Partial<{ volumes: string[]; sections: string[] }>
    ) => {
      if (!config) return;
      const extraction = getExtraction(storage, extractionId);

      if (!extraction?.get("instantDraftConfig")) {
        extraction?.set("instantDraftConfig", createInstantDraftConfig());
      }
      const liveConfig = extraction?.get("instantDraftConfig");
      const volumeAssignment = (liveConfig.get("volumes").toJSON() as ToImmutable<InstantDraftConfig["volumes"]>) || [];
      const sectionAssignment =
        (liveConfig.get("sections").toJSON() as ToImmutable<InstantDraftConfig["sections"]>) || [];

      if (operation === "insert") {
        const newVolumes = uniq([...volumeAssignment, ...(config.volumes || [])]);
        const newSections = uniq([...sectionAssignment, ...(config.sections || [])]);
        update(liveConfig, { volumes: new LiveList(newVolumes), sections: new LiveList(newSections) });
      }

      if (operation === "remove") {
        const newVolumes = pullAll(volumeAssignment, config.volumes || []);
        const newSections = pullAll(sectionAssignment, config.sections || []);
        update(liveConfig, { volumes: new LiveList(newVolumes), sections: new LiveList(newSections) });
      }
    },
    [activeSection?.id, dispatch]
  );

  return {
    addNewSection,
    updateExtractionStep,
    appendExtraction,
    setExtractionRequirementAssignees,
    appendExtractionDrafts,
    setComplianceStatus,
    setSectionName,
    setSectionPageCount,
    setExtractionName,
    setExtractionRequirementSkipped,
    assignExtractionRequirement,
    importExtraction,
    setExtractionErrorReason,
    setExtractionStatus,
    setDraftName,
    addNewVolume,
    deleteDraft,
    deleteExtraction,
    deleteSection,
    bulkUnassignExtractionRequirements,
    setBulkExtractionRequirementsSkipped,
    bulkAssignExtractionRequirements,
    moveSection,
    setBulkExtractionRequirementsMerged,
    createAndInsertRequirement,
    unmergeRequirement,
    appendExtractionDraftsToContextBank,
    deleteContextualDraft,
    insertContextIntoOutline,
    updateInstantDraftConfig,
    toggleInstantDraftOutline,
    setInstantDraftCompleted,
  };
};

export default useExtractionOperations;
